import classNames from "classnames";
import Modal from "./Modal";
import Loader from "./Loader";
import {
  formatPrice,
  updatePriceWithCouponPriceResult,
  updatePriceWithGiftCardResult,
  formatInterval,
  formatInsertables,
  formatBrowserTimezone,
  capitalize
} from "../util";
import { useState, useEffect } from "react";
import LoginModalInner from "./LoginModalInner";
import { useAuth } from "../hooks/useAuth";
import { usePrevious } from "../hooks/usePrevious";
import Button from "./Button";
import moment from "moment";
import { useDebouncedCallback } from "use-debounce";

import rangeStyles from "../styles/range-input.module.css";
import AvailabilityInformation from "./AvailabilityInformation";
import CheckoutSupplementaryData from "./CheckoutSupplementaryData";
import CheckoutPassSupplementaryData from "./CheckoutPassSupplementaryData";
import OrderPaymentWizard from "./OrderPaymentWizard";
import { generateTrackingPayload } from "../utils/tracking";
import { recordConversionEvent } from "../services/api";

const STATE_LOGIN = 0;
const STATE_CONFIRM = 2;
const STATE_COUPON = 3;
const STATE_GIFT_DETAILS = 4;
const STATE_GIFT_SUCCESS = 5;
const STATE_SUPPLEMENTARY_DATA = 6;
const STATE_PASS = 7;
const STATE_PASS_SUPPLEMENTARY_DATA = 8;
const STATE_PASS_SUPPLEMENTARY_DATA_UPDATE = 9;

export default function CheckoutModal({
  event,
  eventId,
  pass,
  salesOption,
  isDataUpdate,
  onSuccess,
  ...rest
}) {
  const {
    user,
    token,
    tenant,
    singleOrganization,
    api,
    onLoginExpired
  } = useAuth();

  const isEvent = typeof event !== "undefined";
  const isPass = typeof pass !== "undefined";
  const [isApp, setIsApp] = useState(false);
  const [allowInAppPurchases, setAllowInAppPurchases] = useState(false);
  const [error, setError] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [
    waitingForCheckoutCompletion,
    setWaitingForCheckoutCompletion
  ] = useState(null);

  useEffect(() => {
    if (window.AARP && tenant.adobe_tag_manager_launch_id) {
      window.AARP.MetaInfo.pagedata.pagename = document.title;
      window.AARP.MetaInfo.pagedata.var1 = "EVENTIVE";
      window.AARP.MetaInfo.pagedata.var2 = "event registration";
      window.AARP.MetaInfo.pagedata.var3 = tenant
        ? tenant?.display_short_name
        : null;
      window.AARP.MetaInfo.pagedata.var4 = event.name;

      window.AARP.MetaInfo.userdata.memprogram = "EVENTIVE";
      window.AARP.MetaInfo.userdata.status = user ? "loggedin" : "anonymous";
      window.AARP.MetaInfo.userdata.appstep = "event purchase";
      window.AARP.MetaInfo.userdata.zipcode = user
        ? user.details?.supplementary_data?.postal_code
        : null;
      window.AARP.MetaInfo.userdata.productcode = "EVENTIVE";

      window.AARP.MetaInfo.errors.errorcode = null;
      window.AARP.MetaInfo.errors.errormessage = null;
    }
  }, []);

  useEffect(() => {
    const isApple =
      navigator.userAgent.toLowerCase().indexOf("gonative") > -1 &&
      /iPad|iPhone|iPod/.test(navigator.userAgent);

    const isAndroid =
      navigator.userAgent.toLowerCase().indexOf("gonativeandroid") > -1;

    const isMobileApp = isApple || isAndroid;
    setIsApp(isMobileApp);
    setAllowInAppPurchases(tenant?.allow_in_app_purchases || !isApple);
  }, []);

  const [isApplyingCoupon, setIsApplyingCoupon] = useState(false);
  const [isEditingPass, setIsEditingPass] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState(null);

  const [donationAmount, setDonationAmount] = useState(0);
  const [donationOtherSelected, setDonationOtherSelected] = useState(false);
  const [donationOtherValue, setDonationOtherValue] = useState("");
  const [updateDonationAmountFromOtherValue] = useDebouncedCallback(
    newAmount => {
      setDonationAmount(newAmount);
    },
    250
  );
  const previousDonationAmount = usePrevious(donationAmount);

  const [pwycValueHasChanged, setPwycValueHasChanged] = useState(false);
  const [donationValueHasChanged, setDonationValueHasChanged] = useState(false);

  const [
    hasUnlockedEventBucketSales,
    setHasUnlockedEventBucketSales
  ] = useState(false);
  const eventBucketSalesLocked =
    !!event?.standalone_ticket_sales_locked && !hasUnlockedEventBucketSales;
  const couponRequiredToUnlock =
    eventBucketSalesLocked || !!event?.sales_disabled_unless_coupon;

  const [isGift, setIsGift] = useState(!!salesOption?.gift);
  const [hasConfirmedGiftRecipient, setHasConfirmedGiftRecipient] = useState(
    false
  );
  const [giftRecipient, setGiftRecipient] = useState("");
  const [giftMessage, setGiftMessage] = useState("");

  const [
    hasEnteredSupplementaryData,
    setHasEnteredSupplementaryData
  ] = useState(false);
  const supplementaryDataRequired =
    !isPass &&
    !!tenant?.virtual_festival_supplementary_data &&
    (!tenant?.virtual_festival_supplementary_data_events ||
      tenant.virtual_festival_supplementary_data_events.includes(eventId)) &&
    !hasEnteredSupplementaryData;

  const [
    hasEnteredPassSupplementaryData,
    setHasEnteredPassSupplementaryData
  ] = useState(false);
  const [passSupplementaryData, setPassSupplementaryData] = useState([]);
  const [passNameData, setPassNameData] = useState([]);

  const [doEmail, setDoEmail] = useState(
    tenant?.virtual_festival_mailing_list_opt_in_unchecked
      ? false
      : !event?.person?.do_not_email
  );

  const selectedSalesOption = salesOption || {};
  const currency = selectedSalesOption.currency || tenant.currency;

  const [displayVariablePrice, setDisplayVariablePrice] = useState(
    formatPrice(salesOption?.price, {
      short: true,
      currency: currency
     })
  );
  const [price, setPrice] = useState(salesOption?.price);

  let ticketBucket = null;

  let fee = 0,
    total = 0,
    isVariablePrice,
    defaultPrice,
    taxPercent = 0,
    taxAmount = 0;

  if (!isDataUpdate) {
    if (isPass) {
      isVariablePrice = false;
      defaultPrice =
        discountedPrice !== null
          ? discountedPrice
          : selectedSalesOption.price || 0;
      fee = pass.checkout_options.fee || 0;
      taxPercent = pass.checkout_options.tax_percent || 0;
      taxAmount = taxPercent * defaultPrice;
    } else {
      ticketBucket = event.ticket_buckets.find(
        b => b.id === selectedSalesOption.ticket_bucket_id
      );

      isVariablePrice =
        ticketBucket?.variable_price && !selectedSalesOption.pass;

      defaultPrice =
        (discountedPrice !== null
          ? discountedPrice
          : selectedSalesOption.price) + donationAmount;

      fee =
        price - donationAmount > 0
          ? typeof event.checkout_options.fee === "object"
            ? // Extract specific fee for given variant/ticket bucket
              event.checkout_options.fee[
                selectedSalesOption.variantId ||
                  selectedSalesOption.ticket_bucket_id
              ]
            : event.checkout_options.fee
          : 0;
      taxPercent = event.checkout_options.tax_percent || 0;
      taxAmount = taxPercent ? taxPercent * (price - donationAmount) : 0;
    }

    total = price + (fee || 0) + (taxAmount || 0);
  }

  const [updatePriceDebouncedFromSlider] = useDebouncedCallback(newPrice => {
    // don't update the price if it's below the minimum
    if (newPrice < ticketBucket.variable_price_minimum) {
      const formattedPrice = formatPrice(ticketBucket.variable_price_minimum, {
        short: true,
        currency: currency
      });
      setValidationError(
        `You must select a price that is more than the ${formattedPrice} minimum`
      );
      setDisplayVariablePrice("");
      return setPrice(ticketBucket.variable_price_minimum);
    }

    setValidationError(null);
    let updatedPrice = newPrice;
    if (donationAmount > 0) {
      updatedPrice = newPrice + donationAmount;
    }
    setPrice(updatedPrice);
    if (isVariablePrice) {
      setDisplayVariablePrice(
        formatPrice(+newPrice, {
          short: true,
          currency: currency
        })
      );
    }
  }, 250);

  useEffect(() => {
    setPrice(defaultPrice);
  }, [defaultPrice]);

  useEffect(() => {
    if (isVariablePrice) {
      if (price) {
        let previousAmount = previousDonationAmount;
        if (typeof previousAmount === "undefined") {
          previousAmount = 0;
        }
        let variablePrice = price - previousAmount;
        setPrice(variablePrice + donationAmount);
        setDisplayVariablePrice(
          formatPrice(+variablePrice, {
            short: true,
            currency: currency
          })
        );
      }
      else {
        setPrice(ticketBucket.variable_price_minimum 
          ? ticketBucket.variable_price_minimum + donationAmount
          : donationAmount
        );
      }
    }
  }, [donationAmount]);

  const userToState = (
    user,
    { skipGift, skipCoupon, skipSupplementaryData } = {}
  ) =>
    isDataUpdate
      ? STATE_PASS_SUPPLEMENTARY_DATA_UPDATE
      : isPass && hasEnteredPassSupplementaryData && user
      ? STATE_PASS
      : isPass && !hasEnteredPassSupplementaryData && user
      ? STATE_PASS_SUPPLEMENTARY_DATA
      : couponRequiredToUnlock && discountedPrice === null && !skipCoupon
      ? STATE_COUPON
      : isGift && !hasConfirmedGiftRecipient && !skipGift
      ? STATE_GIFT_DETAILS
      : user
      ? supplementaryDataRequired && !skipSupplementaryData
        ? STATE_SUPPLEMENTARY_DATA
        : STATE_CONFIRM
      : STATE_LOGIN;

  const [state, setState] = useState(userToState(user));

  useEffect(() => {
    if (couponRequiredToUnlock && discountedPrice === null) {
      setState(STATE_COUPON);
    } else if (supplementaryDataRequired && user) {
      setState(STATE_SUPPLEMENTARY_DATA);
    }
  }, [state, price]);

  useEffect(() => {
    setState(userToState(user));
  }, [user]);

  let orderPrice = !pwycValueHasChanged && !donationValueHasChanged ? price : donationAmount > 0 ? total - donationAmount : price;
  // handles specific case where OTHER hand-entered donationAmount has not registered yet and creates a negative value
  if (orderPrice < 0) { orderPrice = price; }

  if (ticketBucket?.variable_price_minimum && price < ticketBucket?.variable_price_minimum) {
    orderPrice = ticketBucket.variable_price_minimum;
  }

  const orderBody = {
    order: {
      presentmentCurrency: currency,
      subitems: [
        {
          ticket_bucket: isPass ? null : selectedSalesOption.ticket_bucket_id,
          variant: isPass ? null : selectedSalesOption.variantId,
          pass_bucket: isPass ? pass.id : null,
          quantity: 1,
          ...(isGift
            ? {
                gift: true,
                gift_recipient: giftRecipient,
                gift_message: giftMessage
              }
            : null),
          ...(isVariablePrice && price >= ticketBucket.variable_price_minimum
            ? {
                price: orderPrice
              }
            : null)
        }
      ].concat(
        donationAmount
          ? [
              {
                inline_donation: true,
                price: donationAmount
              }
            ]
          : []
      ),
      pass: selectedSalesOption.pass,
      coupon: couponCode || undefined,
      with_credits: selectedSalesOption.with_credits,
      do_not_email: !doEmail,
      tracking_data: generateTrackingPayload()
    },
    event_bucket: tenant.event_bucket,
    version: "2",
    token
  };

  //console.log(orderBody);

  const onApplyCoupon = e => {
    e.preventDefault();

    setIsApplyingCoupon(true);
    setError(null);

    if (eventBucketSalesLocked) {
      api
        .post(`event_buckets/${tenant.event_bucket}/unlock`, {
          token,
          password: couponCode
        })
        .then(() => {
          setIsApplyingCoupon(false);
          setHasUnlockedEventBucketSales(true);
          setState(userToState(user, { skipCoupon: true }));
        })
        .catch(e => {
          setError(e.message);
          setIsApplyingCoupon(false);
        });
      return;
    }

    api
      .post(`coupons/test`, {
        ...orderBody,
        order: JSON.stringify(orderBody.order),
        code: couponCode
      })
      .then(({ data }) => {
        if (tenant.gift_cards_enabled && data.is_applicable && data.gift_card) {
          setDiscountedPrice(
            updatePriceWithGiftCardResult(
              selectedSalesOption.price,
              data.gift_card_balance
            )
          );
          setIsApplyingCoupon(false);
          setState(userToState(user, { skipCoupon: true }));
        }
        else if (data.is_applicable && data.subitems[0].is_applicable) {
          setDiscountedPrice(
            updatePriceWithCouponPriceResult(
              selectedSalesOption.price,
              data.subitems[0].price
            )
          );
          setIsApplyingCoupon(false);
          setState(userToState(user, { skipCoupon: true }));
        } else {
          setError(data.error);
          setIsApplyingCoupon(false);
        }
      })
      .catch(e => {
        setError(e.message);
        setIsApplyingCoupon(false);
      });
  };

  const onAddGiftDetails = e => {
    e.preventDefault();
    setHasConfirmedGiftRecipient(true);
    setState(userToState(user, { skipGift: true }));
  };

  const onOrderCompleted = order => {
    // Custom tracking scripts for customer sites
    if (window.__ev_gat) {
      window.ga(
        `${window.__ev_gat}.send`,
        "event",
        "Eventive Virtual",
        "Purchase",
        isPass ? pass.name : event.name,
        total
      );
    }
    // Track via Meta Conversion API (for Eventive Pixel)
    // NOTE: This is fire and forget, so we are not using await
    recordConversionEvent(
      {
        email: user?.email,
        eventType: "Purchase",
        price: total - donationAmount,
        currency: currency,
        tenant: tenant
      }
    );
    if (window.__ev_fbpt) {
      // track via Pixel (for organization pixel)
      window.fbq("trackSingle", window.__ev_fbpt, "Purchase", {
        content_ids: isPass ? [pass.id] : [eventId],
        content_name: isPass ? pass.name : event.name,
        content_type: "product",
        currency: currency,
        value: total - donationAmount
      });
      if (donationAmount && event) {
        // Track via Meta Conversion API (for Eventive pixel)
        // NOTE: This is fire and forget, so we are not using await
        recordConversionEvent(
          {
            email: user?.email,
            eventType: "Donate",
            price: donationAmount,
            currency: currency,
            tenant: tenant
          }
        );
        // track via Pixel (for organization pixel)
        window.fbq("trackSingle", window.__ev_fbpt, "Donate", {
          content_ids: [eventId],
          content_name: event.name,
          content_type: "product",
          currency: currency,
          value: donationAmount
        });
      }
    }
    if (window.AARP && tenant.adobe_tag_manager_launch_id) {
      document.dispatchEvent(
        new CustomEvent("event_registration", {
          detail: {
            "data-eventName": event.name,
            "data-eventFeature": event.films && event.films.length && event.films.length > 0 ? event.films[0].name : event.name,
            "data-pageName": document.title,
            "data-registration": order?.id ? order.id : order?.order?.id
          }
        })
      );
    }

    if (isGift) {
      setState(STATE_GIFT_SUCCESS);
      setWaitingForCheckoutCompletion(false);
    } else {
      onSuccess(order);
    }
  };

  const onUpdatePassDetails = details => {
    setIsApplyingCoupon(true);
    setError(null);

    let jsonBody = { supplementary_data_obj: {} };
    let jsonKeys = Object.keys(details);
    for (let k = 0; k < jsonKeys.length; k++) {
      const jsonKey = jsonKeys[k];
      if (jsonKey === "name") {
        jsonBody.name = details[jsonKey];
      } else {
        jsonBody.supplementary_data_obj[jsonKey] = details[jsonKey];
      }
    }

    // this is necessary because the server is expecting
    // a string for supplementary_data
    jsonBody.supplementary_data = JSON.stringify(
      jsonBody.supplementary_data_obj
    );
    delete jsonBody.supplementary_data_obj;

    api
      .post(`passes/${pass.id}`, jsonBody)
      .then(({ data }) => {
        setIsApplyingCoupon(false);
        onSuccess(data);
      })
      .catch(e => {
        setError(e.message);
        setIsApplyingCoupon(false);
      });
  };

  useEffect(() => {
    if (tenant.use_aarp_registration_flow && user) {
      setLoading(true);
      api
        .post(`orders`, orderBody)
        .then(r => onOrderCompleted(r.data))
        .catch(e => {
          window.alert(e.message);
          onSuccess();
        });
    }
  }, [tenant, user, setLoading]);

  const changeDoEmailSetting = e => {
    setDoEmail(e.target.checked);
  };

  if (
    waitingForCheckoutCompletion ||
    window.location.href.indexOf("_complete_checkout") !== -1
  ) {
    return (
      <Modal
        id="checkout"
        medium={true}
        {...rest}
        allowBackgroundDimiss={false}
      >
        <OrderPaymentWizard
          waitingForCheckoutCompletion={waitingForCheckoutCompletion}
          orderData={orderBody}
          apiRequest={api.request}
          onOrderCanceled={() => onSuccess()}
          onOrderCompleted={onOrderCompleted}
          onLoginExpired={onLoginExpired}
        />
      </Modal>
    );
  }

  if (isApp && !allowInAppPurchases) {
    return (
      <Modal id="checkout" {...rest} allowBackgroundDimiss>
        <p className="text-center p-4">
          Sorry, ordering is not available via the app. Please complete your
          purchase and then return to this page.
        </p>
      </Modal>
    );
  }

  if (
    !event?.standalone_ticket_sales_enabled &&
    !selectedSalesOption.pass &&
    singleOrganization?.ida_login &&
    !tenant?.ida_login_hide_custom_text
  ) {
    return (
      <Modal id="checkout" {...rest} allowBackgroundDimiss>
        <p className="text-center p-4">
          Sorry, this screening is only available if you are a current IDA
          member. Please Login with your MyIDA account to access. Not part of
          the IDA family?{" "}
          <a
            className="text-indigo-600 underline hover:text-indigo-700"
            href="https://documentary.org/membership"
          >
            Click here to join or renew your membership.
          </a>
        </p>
        <LoginModalInner
          ssoOnly
          ticketBucket={ticketBucket}
          targetResource={{
            event_bucket: tenant.event_bucket,
            target_resource_type: "virtual_event",
            target_resource_id: eventId
          }}
        />
      </Modal>
    );
  }

  if (singleOrganization?.sundance_login && !user) {
    return (
      <Modal id="checkout" {...rest} allowBackgroundDimiss>
        <LoginModalInner
          sundanceOnly
          ticketBucket={ticketBucket}
          targetResource={{
            event_bucket: tenant.event_bucket,
            target_resource_type: "virtual_event",
            target_resource_id: eventId
          }}
        />
      </Modal>
    );
  }

  if (tenant.use_aarp_registration_flow && !user) {
    return (
      <Modal id="checkout" {...rest} allowBackgroundDimiss>
        <p className="text-center p-4">
          Login with your AARP account to access this screening.
        </p>
        <LoginModalInner
          aarpOnly
          ticketBucket={ticketBucket}
          targetResource={{
            event_bucket: tenant.event_bucket,
            target_resource_type: isPass ? "pass" : "virtual_event",
            target_resource_id: isPass ? pass.id : eventId
          }}
        />
      </Modal>
    );
  }

  if (tenant.use_aarp_registration_flow && user && loading) {
    return (
      <Modal id="checkout" {...rest} allowBackgroundDimiss>
        <div className="p-4">
          <Loader className="transform scale-150 text-always-gray-500 mx-auto" />
        </div>
      </Modal>
    );
  }

  if (!isPass) {
    if (!event.standalone_ticket_sales_enabled && !selectedSalesOption.pass) {
      return (
        <Modal id="checkout" {...rest} allowBackgroundDimiss>
          {tenant?.virtual_festival_standalone_ticket_sales_disabled_error
            ?.__html ? (
            <>
              <div
                className="tenant-injected-html text-center p-4"
                dangerouslySetInnerHTML={{
                  __html:
                    tenant
                      .virtual_festival_standalone_ticket_sales_disabled_error
                      .__html
                }}
              />
              {!user ? (
                <div className="border-t border-gray-300 mt-2 p-4 pt-6 italic text-always-gray-600 text-center">
                  If you believe you are authorized, or have a pass or
                  membership, try logging in first.
                </div>
              ) : null}
            </>
          ) : (
            <p className="text-center p-4">
              Sorry, this screening is not currently available for standalone
              unlocks.{" "}
              {!user
                ? `If you believe you are authorized, or have a pass or membership, try logging in first.`
                : null}
            </p>
          )}
        </Modal>
      );
    }
  }

  let donationLevels = [{ price: 0 }]
    .concat(
      tenant.virtual_festival_donate_inline_levels
        ? tenant.virtual_festival_donate_inline_levels.map(x => ({
            price: x.amount
          }))
        : [{ price: 200 }, { price: 500 }, { price: 1000 }]
    )
    .concat([{ custom: true }]);

  return (
    <Modal
      id="checkout"
      medium={true}
      allowBackgroundDimiss={false}
      showDismissButton={true}
      {...rest}
    >
      <h2 className="text-xl font-semibold text-center tracking-tight leading-tight text-always-gray-800 px-6">
        {/* padding for close button */}
        {isDataUpdate
          ? `Edit ${tenant.display_pass} details for`
          : isPass
          ? "Purchase"
          : isGift
          ? "Gift"
          : selectedSalesOption.preorder
          ? "Pre-order"
          : "Unlock"}{" "}
        <span className="no-translate">{isPass ? pass.name : event.name}</span>{" "}
        {isDataUpdate ? (
          ""
        ) : isVariablePrice ? (
          "at a price you choose"
        ) : tenant?.virtual_festival_hide_free_prices && total === 0 ? (
          ""
        ) : (
          <>
            for&nbsp;
            <span
              className={
                discountedPrice !== null &&
                discountedPrice !== selectedSalesOption.price
                  ? "line-through"
                  : ""
              }
            >
              {formatPrice(
                discountedPrice !== null
                  ? selectedSalesOption.price
                  : total - donationAmount,
                {
                  short: true,
                  currency: currency
                }
              )}
            </span>{" "}
            {discountedPrice !== null &&
            discountedPrice !== selectedSalesOption.price
              ? formatPrice(total - donationAmount, {
                  short: true,
                  currency: currency
                })
              : null}
          </>
        )}
      </h2>
      {!isPass && selectedSalesOption.pass ? (
        <h2 className="text-lg text-center tracking-tight leading-tight text-always-gray-600 mb-1">
          using your pass "{selectedSalesOption.pass_name}"
        </h2>
      ) : null}
      {fee || taxAmount ? (
        <h3 className="text-sm text-center text-always-gray-600 font-medium mt-2 mb-1">
          {formatPrice(price - donationAmount, {
            currency: currency
          })}{" "}
          {fee
            ? `+ ${formatPrice(fee, {
                currency: currency
              })} fee`
            : ""}{" "}
          {taxAmount
            ? `+ ${formatPrice(taxAmount, {
                currency: currency
              })} tax`
            : ""}
        </h3>
      ) : null}
      {!isDataUpdate &&
      (!isVariablePrice && (isPass ||
        (state !== STATE_COUPON &&
          state !== STATE_GIFT_SUCCESS &&
          selectedSalesOption.price))) ? (
        <div className="text-center">
          <button
            className="bg-always-gray-200 inline-flex items-center my-2 px-3 py-1 border border-transparent rounded-md transition ease-in-out duration-150 text-always-gray-800 text-sm hover:bg-always-gray-300 active:bg-always-gray-300 focus:bg-always-gray-300 focus:outline-none focus:shadow-outline-blue cursor-pointer"
            onClick={() => setState(STATE_COUPON)}
          >
            <svg
              className="w-4 h-4 mr-1 inline-block"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fill-rule="evenodd"
                d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.997.997 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span>
              {discountedPrice !== null
                ? couponRequiredToUnlock
                  ? "Access verified"
                  : tenant.gift_cards_enabled
                  ? "Code applied"
                  : "Discount applied"
                : tenant.gift_cards_enabled
                ? "Have a discount or gift card code?"
                : "Have a discount code?"
              }
            </span>
          </button>
        </div>
      ) : null}
      {state === STATE_COUPON ? (
        <div className="mt-6">
          {couponRequiredToUnlock ? (
            <div className="mb-6 font-normal text-always-gray-600 leading-5 text-sm text-center">
              Sales are currently locked. If you have a code, you can unlock
              them.
            </div>
          ) : null}
          <form className="flex" onSubmit={onApplyCoupon}>
            <div className="flex-1 relative rounded-md shadow-sm mr-2">
              <input
                id="coupon"
                className="form-input block w-full text-sm sm:leading-5"
                placeholder={couponRequiredToUnlock 
                  ? "Code"
                  : tenant.gift_cards_enabled
                  ? "Discount or gift card code"
                  : "Discount code"
                }
                autoFocus
                value={couponCode}
                onChange={e => setCouponCode(e.target.value.trim())}
              />
            </div>
            <Button type="submit" disabled={isApplyingCoupon}>
              {couponRequiredToUnlock
                ? isApplyingCoupon
                  ? "Verifying access..."
                  : "Verify access"
                : isApplyingCoupon
                ? tenant.gift_cards_enabled
                  ? "Applying code..."
                  : "Applying discount"
                : tenant.gift_cards_enabled
                  ? "Apply code"
                  : "Apply discount"
              }
            </Button>
          </form>
          {error ? (
            <p className="mt-2 text-sm text-red-600 text-center">{error}</p>
          ) : null}
          {!couponRequiredToUnlock ? (
            <div className="mt-4 text-center">
              <a
                className="text-always-gray-500 text-sm hover:text-always-gray-600 cursor-pointer hover:underline"
                onClick={() => {
                  setError(null);
                  setCouponCode("");
                  setDiscountedPrice(null);
                  setState(userToState(user));
                }}
              >
                Never mind
              </a>
            </div>
          ) : null}
        </div>
      ) : state === STATE_LOGIN ? (
        <div className="mt-3">
          <LoginModalInner
            ticketBucket={ticketBucket}
            targetResource={{
              event_bucket: tenant.event_bucket,
              target_resource_type: isPass ? "pass" : "virtual_event",
              target_resource_id: isPass ? pass.id : eventId
            }}
            onSuccess={data => {
              if (data.target_resource_safe === false) {
                // If the newly logged in user has passes, already has a ticket, etc.,
                // close the checkout and give them the opportunity to use them
                onSuccess();
              }
            }}
          />
        </div>
      ) : state === STATE_CONFIRM ? (
        <>
          {isVariablePrice ? (
            <div className="mt-5">
              <div>
                <p className="text-base text-always-gray-600 leading-tight text-center">
                  {tenant.virtual_festival_pay_what_you_can_custom_message ||
                  `Access to this program is available on a sliding scale. Please pay what you can.`} 
                </p>
                <div className="flex items-center my-4 px-6">
                  <div className="flex-1 mr-4">
                    <input
                      type="range"
                      className={rangeStyles["range-input"]}
                      min={ticketBucket.variable_price_minimum}
                      max={tenant.virtual_festival_pay_what_you_can_max_slider_value || 5000}
                      step={100}
                      value={
                        displayVariablePrice
                          ? +displayVariablePrice.replace(/[^0-9.]/g, "") * 100
                          : price
                      }
                      onChange={e => {
                        setDisplayVariablePrice(
                          formatPrice(+e.target.value, {
                            short: true,
                            currency: currency
                          })
                        );
                        updatePriceDebouncedFromSlider(+e.target.value);
                        setPwycValueHasChanged(true);
                      }}
                    />
                    <div className="flex items-center text-xs text-always-gray-400">
                      <div>
                        {formatPrice(ticketBucket.variable_price_minimum, {
                          short: true,
                          currency: currency
                        })}
                        &nbsp;&ndash;&nbsp;
                        {formatPrice(tenant.virtual_festival_pay_what_you_can_max_slider_value || 5000, {
                          short: true,
                          currency: currency
                        })}
                        +
                      </div>
                      <div className="flex-1 text-always-gray-500 text-right font-semibold">
                        Drag ↑ or type →
                      </div>
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-input w-24 text-center"
                    value={
                      displayVariablePrice 
                        ? displayVariablePrice 
                        : donationAmount > 0
                        ? formatPrice(price - donationAmount, {short: true, currency: currency})
                        : formatPrice(price, {short: true, currency: currency})
                    }
                    onChange={e => {
                      setDisplayVariablePrice(e.target.value);
                      let amount =
                        (+e.target.value.replace(/[^0-9.]/g, "") || 0) * 100;
                      if (amount !== 0) {
                        if (amount < ticketBucket.variable_price_minimum) {
                          amount = ticketBucket.variable_price_minimum;
                        }
                        updatePriceDebouncedFromSlider(amount);
                        setPwycValueHasChanged(true);
                      }
                      else {
                        console.log("Invalid Pay What You Can amount");
                      }
                    }}
                    onBlur={e => {
                      setDisplayVariablePrice("");
                    }}
                  />
                </div>

                {validationError ? (
                  <p className="mt-2 text-sm text-red-600 text-center">
                    {validationError}
                  </p>
                ) : null}
              </div>
            </div>
          ) : null}
          {!isPass &&
          event.checkout_options.show_donate_prompt_with_message &&
          (!isVariablePrice || tenant.virtual_festival_donation_allowed_with_pay_what_you_can) ? (
            <div className="mt-5 -mx-8 px-8 py-4 bg-always-gray-100">
              <div className="text-xs text-always-gray-500 mr-4 leading-tight text-center no-translate">
                {formatInsertables(
                  event.checkout_options.show_donate_prompt_with_message ||
                    `Support ${tenant.display_short_name} and cover our streaming costs with an optional donation.`,
                  { name: () => tenant.display_short_name }
                )}
              </div>
              <div className="mt-3 relative z-0 flex justify-center">
                {donationLevels.map(({ price, custom }, i) => (
                  <button
                    type="button"
                    className={classNames(
                      "relative inline-flex items-center px-4 py-2 border border-always-gray-300 bg-always-white text-sm leading-5 font-medium text-always-gray-700 hover:text-always-gray-500 focus:z-10 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:bg-always-gray-100 active:text-always-gray-700 transition ease-in-out duration-150 no-translate",
                      i === 0
                        ? "rounded-l-md"
                        : i === donationLevels.length - 1
                        ? "rounded-r-md -ml-px"
                        : "-ml-px",
                      (custom && donationOtherSelected) ||
                        (!donationOtherSelected && donationAmount === price)
                        ? "shadow-outline-indigo border-indigo-300 bg-indigo-100 z-10"
                        : ""
                    )}
                    onClick={e => {
                      if (custom) {
                        setDonationValueHasChanged(true);
                        if (!donationOtherSelected) {
                          setDonationOtherSelected(true);
                          setDonationOtherValue(
                            formatPrice(0, {
                              short: true,
                              currency: currency
                            }).replace(/[0-9]/g, "")
                          );
                        }
                      } else {
                        setDonationAmount(price);
                        setDonationOtherSelected(false);
                      }
                    }}
                  >
                    {custom ? (
                      donationOtherSelected ? (
                        <div>
                          <input
                            type="text"
                            className="w-12 outline-none bg-transparent font-medium text-sm leading-5 text-always-gray-700"
                            autoFocus
                            value={donationOtherValue}
                            onChange={e => {
                              setDonationOtherValue(e.target.value);
                              const amount =
                                (+e.target.value.replace(/[^0-9.]/g, "") || 0) *
                                100;
                              updateDonationAmountFromOtherValue(amount);
                              setDonationValueHasChanged(true);
                            }}
                            onBlur={e => {
                              if (!donationAmount) {
                                setDonationOtherSelected(false);
                              } else {
                                setDonationValueHasChanged(true);
                                setDonationOtherValue(
                                  formatPrice(donationAmount, {
                                    short: true,
                                    currency: currency
                                  })
                                );
                              }
                            }}
                          />
                        </div>
                      ) : (
                        "Other"
                      )
                    ) : (
                      formatPrice(price, {
                        short: true,
                        currency: currency
                      })
                    )}
                  </button>
                ))}
              </div>
            </div>
          ) : null}
          {isGift ? (
            <div className="text-center text-sm text-always-gray-500 mt-6">
              <div>
                Gift will be emailed to{" "}
                <span className="font-bold">{giftRecipient}</span>
                &nbsp;&middot;&nbsp;
                <a
                  className="hover:underline cursor-pointer hover:text-always-gray-600"
                  onClick={() => setState(STATE_GIFT_DETAILS)}
                >
                  change
                </a>
              </div>
              <div className="mt-2 italic">
                {giftMessage || "No additional message"}
              </div>
            </div>
          ) : null}
          {fee || taxAmount ? (
            <div className="text-left text-sm text-always-gray-500 mt-6">
              <table
                className="mx-auto"
                style={{ fontVariantNumeric: "tabular-nums" }}
              >
                <tr>
                  <td className="uppercase text-xs font-medium align-bottom">
                    Subtotal
                  </td>
                  <td className="pl-4">
                    {formatPrice(price - donationAmount, {
                      currency: currency,
                      tabularWidth: 6
                    })}
                  </td>
                </tr>
                {fee ? (
                  <tr>
                    <td className="uppercase text-xs font-medium align-bottom">
                      Fee
                    </td>
                    <td className="pl-4">
                      {formatPrice(fee, {
                        currency: currency,
                        tabularWidth: 6
                      })}
                    </td>
                  </tr>
                ) : null}
                {taxAmount ? (
                  <tr>
                    <td className="uppercase text-xs font-medium align-bottom">
                      Sales tax (
                      {taxPercent.toLocaleString(undefined, {
                        style: "percent",
                        maximumFractionDigits: 4
                      })}
                      )
                    </td>
                    <td className="pl-4">
                      {formatPrice(taxAmount, {
                        currency: currency,
                        tabularWidth: 6
                      })}
                    </td>
                  </tr>
                ) : null}
                {donationAmount ? (
                  <tr>
                    <td className="uppercase text-xs font-medium align-bottom">
                      Donation
                    </td>
                    <td className="pl-4">
                      {formatPrice(donationAmount, {
                        currency: currency,
                        tabularWidth: 6
                      })}
                    </td>
                  </tr>
                ) : null}
                <tr className="h-1" />
                <tr className="border-t border-always-gray-300">
                  <td className="uppercase text-xs font-medium align-bottom pt-1">
                    Total
                  </td>
                  <td className="pl-4 pt-1 font-medium">
                    {formatPrice(total, {
                      currency: currency,
                      tabularWidth: 6
                    })}
                  </td>
                </tr>
              </table>
            </div>
          ) : null}
          {event.show_mailing_list_opt_in && (
            <div className="my-6">
              <label className="text-always-gray-500 text-left text-sm flex justify-center">
                <input
                  className="mr-2 form-checkbox"
                  type="checkbox"
                  style={{ marginTop: "0.2rem" }}
                  checked={doEmail}
                  onChange={changeDoEmailSetting}
                />
                <div
                  className="tenant-injected-html"
                  dangerouslySetInnerHTML={{
                    __html: event.mailing_list_opt_in_message
                  }}
                />
              </label>
            </div>
          )}
          <div className="text-center my-6">
            <OrderPaymentWizard
              orderData={orderBody}
              apiRequest={api.request}
              onOrderWaitingForCompletion={data =>
                setWaitingForCheckoutCompletion(data)
              }
              onOrderCanceled={() => onSuccess()}
              onOrderCompleted={onOrderCompleted}
              onLoginExpired={onLoginExpired}
              renderSubmitButton={({
                disabled,
                isWorking,
                isSubmittingOrder
              }) => {
                return (
                  <Button spinner={isWorking} disabled={disabled} type="submit">
                    {isSubmittingOrder
                      ? "Unlocking..."
                      : `${
                          isGift
                            ? "Gift now"
                            : selectedSalesOption.preorder
                            ? "Pre-order now"
                            : "Unlock now"
                        }${
                          tenant?.virtual_festival_hide_free_prices &&
                          total === 0
                            ? ""
                            : ` (${formatPrice(total, {
                                short: true,
                                currency: currency
                              })})`
                        }`}
                  </Button>
                );
              }}
            />
            {error ? (
              <p className="mt-2 text-sm text-red-600 text-center">{error}</p>
            ) : null}
          </div>
          <p className="leading-snug text-always-gray-400 text-sm">
            {event.realtime_broadcast ? (
              event.realtime_broadcast_become_vod_afterwards ? (
                <AvailabilityInformation
                  event={event}
                  numberOfFilms={
                    event.films?.filter(f => f.type !== "livestream").length
                  }
                />
              ) : (
                <>
                  This screening will broadcast live{" "}
                  {moment(event.start_time).format("MMMM Do [from] h:mm a")} to{" "}
                  {moment(event.end_time).format("h:mm a")}{" "}
                  {formatBrowserTimezone(event.start_time)}. You will not be
                  able to pause or rewind. If you join late or purchase a ticket
                  after the program has begun, you will miss part or all of the
                  program and not receive a refund.
                </>
              )
            ) : (
              <>
                {selectedSalesOption.preorder ? (
                  <>
                    {price !== 0
                      ? `Your card will be charged immediately. `
                      : ""}
                    After the screening becomes available{" "}
                    <var>
                      {moment(event.start_time).format("MMMM Do [at] h:mm a")}
                    </var>
                    , {isGift ? `the recipient will` : `you'll`} receive an{" "}
                    {isGift ? "additional" : ""} email reminder and have{" "}
                    <var>{formatInterval(event.unlock_interval)}</var> to begin
                    watching.
                  </>
                ) : (
                  <>
                    {isGift
                      ? `After accepting, the recipient will`
                      : `After unlocking, you'll`}{" "}
                    have <var>{formatInterval(event.unlock_interval)}</var> to
                    begin watching.
                  </>
                )}{" "}
                Once {isGift ? "they" : "you"} click "Watch now",{" "}
                {isGift ? "they'll" : "you'll"} have unlimited access for{" "}
                <span className="font-semibold">
                  <var>{formatInterval(event.watch_interval)}</var>
                </span>
                .
              </>
            )}
          </p>
        </>
      ) : state === STATE_GIFT_DETAILS ? (
        <div className="mt-6">
          <form onSubmit={onAddGiftDetails}>
            <label
              htmlFor="name"
              className="block text-sm font-medium leading-5 text-always-gray-700"
            >
              Recipient email address
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                id="giftRecipient"
                name="giftRecipient"
                type="email"
                required
                className="form-input block w-full text-sm sm:leading-5"
                placeholder="friend@example.com"
                value={giftRecipient}
                onChange={e => setGiftRecipient(e.target.value)}
                autoFocus={true}
              />
            </div>
            {/*<div className="text-xs text-always-gray-500 mt-1">
              Separate multiple gift recipients with commas.
            </div>*/}
            <label
              htmlFor="name"
              className="mt-3 block text-sm font-medium leading-5 text-always-gray-700"
            >
              Optional message
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <textarea
                id="giftMessage"
                name="giftMessage"
                className="form-input block w-full text-sm sm:leading-5"
                placeholder="Enjoy the movie!"
                value={giftMessage}
                rows={3}
                onChange={e => setGiftMessage(e.target.value)}
              />
            </div>
            <div className="mt-6 text-center">
              <Button type="submit">Continue</Button>
            </div>
          </form>
        </div>
      ) : state === STATE_GIFT_SUCCESS ? (
        <div className="mt-3">
          <svg
            className="h-24 w-24 text-red-300 block mx-auto"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
              clip-rule="evenodd"
              fill-rule="evenodd"
            ></path>
          </svg>
          <div className="text-center text-always-gray-600 mt-3 text-sm">
            Voila! Your gift has been sent to your recipient. You will also
            receive an email confirmation.
          </div>
          <div className="mt-6 text-center">
            <Button onClick={() => onSuccess()}>Close</Button>
          </div>
        </div>
      ) : state === STATE_SUPPLEMENTARY_DATA ? (
        <CheckoutSupplementaryData
          onSuccess={() => {
            setHasEnteredSupplementaryData(true);
            setState(userToState(user, { skipSupplementaryData: true }));
          }}
        />
      ) : state === STATE_PASS ? (
        <div className="text-left text-sm text-always-gray-500 mt-4">
          {isPass && hasEnteredPassSupplementaryData ? (
            <div className="text-center text-sm text-always-gray-500 mb-4">
              <div>
                {capitalize(tenant.display_pass)} will be assigned to{" "}
                <span className="font-bold">{passNameData[0]}</span>
                &nbsp;&middot;&nbsp;
                <a
                  className="hover:underline cursor-pointer hover:text-always-gray-600"
                  onClick={() => {
                    setIsEditingPass(true);
                    setState(STATE_PASS_SUPPLEMENTARY_DATA);
                  }}
                >
                  change
                </a>
              </div>
            </div>
          ) : null}
          <table
            className="mx-auto"
            style={{ fontVariantNumeric: "tabular-nums" }}
          >
            <tr>
              <td className="uppercase text-xs font-medium align-bottom">
                Subtotal
              </td>
              <td className="pl-4">
                {formatPrice(price - donationAmount, {
                  currency: currency,
                  tabularWidth: 6
                })}
              </td>
            </tr>
            {fee ? (
              <tr>
                <td className="uppercase text-xs font-medium align-bottom">
                  Fee
                </td>
                <td className="pl-4">
                  {formatPrice(fee, {
                    currency: currency,
                    tabularWidth: 6
                  })}
                </td>
              </tr>
            ) : null}
            {taxAmount ? (
              <tr>
                <td className="uppercase text-xs font-medium align-bottom">
                  Sales tax (
                  {taxPercent.toLocaleString(undefined, {
                    style: "percent",
                    maximumFractionDigits: 4
                  })}
                  )
                </td>
                <td className="pl-4">
                  {formatPrice(taxAmount, {
                    currency: currency,
                    tabularWidth: 6
                  })}
                </td>
              </tr>
            ) : null}
            {donationAmount ? (
              <tr>
                <td className="uppercase text-xs font-medium align-bottom">
                  Donation
                </td>
                <td className="pl-4">
                  {formatPrice(donationAmount, {
                    currency: currency,
                    tabularWidth: 6
                  })}
                </td>
              </tr>
            ) : null}
            <tr className="h-1" />
            <tr className="border-t border-always-gray-300">
              <td className="uppercase text-xs font-medium align-bottom pt-1">
                Total
              </td>
              <td className="pl-4 pt-1 font-medium">
                {formatPrice(total, {
                  currency: currency,
                  tabularWidth: 6
                })}
              </td>
            </tr>
          </table>
          <div className="text-center mt-6">
            <OrderPaymentWizard
              orderData={{
                order: {
                  presentmentCurrency: currency,
                  subitems: [
                    {
                      pass_bucket: pass.id,
                      quantity: 1,
                      names: passNameData,
                      supplementary_datas: passSupplementaryData,
                      absorb_fee: true
                    }
                  ],
                  coupon: couponCode || undefined,
                  do_not_email: !doEmail,
                  tracking_data: generateTrackingPayload()
                },
                event_bucket: tenant.event_bucket,
                version: "2",
                token
              }}
              apiRequest={api.request}
              onOrderWaitingForCompletion={data =>
                setWaitingForCheckoutCompletion(data)
              }
              onOrderCanceled={() => onSuccess()}
              onOrderCompleted={onOrderCompleted}
              onLoginExpired={onLoginExpired}
              renderSubmitButton={({
                disabled,
                isWorking,
                isSubmittingOrder
              }) => {
                return (
                  <Button spinner={isWorking} disabled={disabled} type="submit">
                    {isSubmittingOrder
                      ? "Purchasing..."
                      : `Buy now${
                          tenant?.virtual_festival_hide_free_prices &&
                          total === 0
                            ? ""
                            : ` (${formatPrice(total, {
                                short: true,
                                currency: currency
                              })})`
                        }`}
                  </Button>
                );
              }}
            />
            {error ? (
              <p className="mt-2 text-sm text-red-600 text-center">{error}</p>
            ) : null}
          </div>
        </div>
      ) : state === STATE_PASS_SUPPLEMENTARY_DATA ? (
        <CheckoutPassSupplementaryData
          pass={pass}
          isEdit={isEditingPass}
          editData={{
            name: passNameData,
            supplementary_data: passSupplementaryData
          }}
          onSuccess={data => {
            let nameData = [data.name];
            delete data.name;
            let supplementaryData = Object.keys(data).map(k => {
              let keyObject = {};
              keyObject[k] = data[k];
              return keyObject;
            });
            setIsEditingPass(false);
            setPassNameData(nameData);
            setPassSupplementaryData(supplementaryData);
            setHasEnteredPassSupplementaryData(true);
            setState(STATE_PASS);
          }}
        />
      ) : state === STATE_PASS_SUPPLEMENTARY_DATA_UPDATE ? (
        <div>
          <CheckoutPassSupplementaryData
            pass={pass}
            isDataUpdate={true}
            onSuccess={data => {
              onUpdatePassDetails(data);
            }}
          />
          {error ? (
            <p className="mt-2 text-sm text-red-600 text-center">{error}</p>
          ) : null}
        </div>
      ) : null}
    </Modal>
  );
}
